@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Graphik:wght@300;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Graphik", sans-serif;
    font-weight: 300;
  }
  body {
    @apply bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 font-light;
  }
}
